import { AddDomainIcon, Box, List, ListItem, styled, Typography } from 'ui';
import { CleanButton } from 'ui/src/utils/clean-button';

export const StyledItemLoader = styled(ListItem)`
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.palette.neutral[200]};
  display: flex;
  justify-content: center;
`;

export const StyledList = styled(List)`
  overflow-y: auto;
`;

export const StyledButton = styled(CleanButton)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
`;

export const StyledIcon = styled(AddDomainIcon)`
  width: 34px;
  height: 34px;
`;

export const StyledFontTypography = styled(Typography)`
  font-weight: bold;
`;

export const StyledBox = styled(Box)`
  .MuiCheckbox-root {
    padding: 0;
    padding-right: 0;
  }
  .MuiCheckbox-root:hover {
    background-color: transparent;
  }
`;
