import { RefObject, useCallback, useEffect } from 'react';
import { debounce } from 'ui';

const DebounceThreshold = 1000;

const ScrollThreshold = 0.6;

export const useInfiniteScroll = (
  scrollableElement: RefObject<HTMLElement>,
  onScroll: () => void,
) => {
  const handleScroll = useCallback(() => {
    if (!scrollableElement?.current) return;
    if (
      scrollableElement.current.scrollTop /
        scrollableElement.current.offsetHeight >
      ScrollThreshold
    ) {
      onScroll();
    }
  }, [onScroll, scrollableElement]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, DebounceThreshold);
    const ref = scrollableElement.current;
    ref?.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      ref?.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [handleScroll, scrollableElement]);
};
