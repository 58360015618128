import toQueryParams from '../../utils/toQueryParams';
import claimScoreApiServiceClient from '../client';
import { Paginated, ValidateTokenResponse } from '../shared/types';
import {
  ActivateAccountPayload,
  ActivateAccountResponse,
  DeleteInvitationPayload,
  DeleteInvitationResponse,
  GetAllInvitationsPayload,
  Invitation,
  InviteToOrganizationPayload,
  InviteToOrganizationResponse,
  ReviewInvitationPayload,
  ReviewInvitationResponse,
  ValidateInvitationPayload,
} from './types';

export const getAllInvitations = async (
  payload: GetAllInvitationsPayload,
): Promise<Paginated<Invitation>> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/invitations?${toQueryParams(payload)}`,
  );
  return data;
};

export const deleteInvitation = async (
  payload: DeleteInvitationPayload,
): Promise<DeleteInvitationResponse> => {
  const { data } = await claimScoreApiServiceClient.delete('/invitations', {
    data: payload,
  });
  return data;
};

export const inviteToOrganization = async (
  payload: InviteToOrganizationPayload,
): Promise<InviteToOrganizationResponse> => {
  const { data } = await claimScoreApiServiceClient.post(
    `/invitations/organizations/${payload.organizationID}`,
    payload,
  );
  return data;
};

export const reviewInvitationRequest = async (
  payload: ReviewInvitationPayload,
): Promise<ReviewInvitationResponse> => {
  const { data } = await claimScoreApiServiceClient.post(
    `/invitations/${payload.invitationID}/reviewAccount`,
    payload,
  );
  return data;
};

export const activateAccount = async (
  payload: ActivateAccountPayload,
): Promise<ActivateAccountResponse> => {
  const { data } = await claimScoreApiServiceClient.post(
    `/invitations/${payload.invitationID}/activateAccount`,
    payload,
  );
  return data;
};

export const validateInvitationToken = async ({
  token,
  invitationId,
}: ValidateInvitationPayload): Promise<ValidateTokenResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/invitations/${invitationId}/validateToken/${token}`,
  );
  return data;
};
