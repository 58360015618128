import React, { ReactNode, useCallback, useMemo } from 'react';
import { useCreateUserCaseRole } from '@hooks/react-query/mutations/useCreateUserCaseRole';
import {
  Box,
  Button,
  ChevronDownIcon,
  ListSelect,
  Modal,
  SelectChangeEvent,
  Spacing,
  Stack,
  styled,
  themeMediaQuery,
  useBreakpoints,
  useToast,
} from 'ui';
import { RowListWithActions } from '@components/RowListWithActions';
import { StepperWrapper } from '@components/StepperWrapper';
import {
  getUserRoleSelectionItems,
  UNASSIGNED_ROLE,
} from '@components/UserRoleDropdown';
import { useLoading } from '@context/LoadingContext';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { OrganizationAccount } from 'services/ClaimscoreApiService/Organizations/types';
import { UserRole } from 'services/ClaimscoreApiService/shared/types';
import { ConfirmationStepContent } from './ConfirmationStepContent/ConfirmationStepContent';
import { InviteToCaseProvider, useInviteToCase } from './InviteToCaseContext';
import { SelectUsersStepContent } from './SelectUsersStepContent/SelectUsersStepContent';

const steps = [
  { label: 'Select members' },
  { label: 'Assign roles' },
  { label: 'Send invitations' },
];

const StyledBox = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(Spacing.Medium)};
  width: 100%;
  justify-content: space-between;
  ${({ theme }) => themeMediaQuery(theme, 'md')`
    justify-content: flex-end;
  `}
`;

const StyledStackContainer = styled(Stack)`
  height: 420px;
  width: 100%;
  ${({ theme }) => themeMediaQuery(theme, 'md')`
  min-width: 600px;
  `}
`;

export const StyledListItemSelect = styled(ListSelect)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  right: 5,
  '&.MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}));

const ASSIGN_ROLES_STEP = 1;
const CONFIRMATION_STEP = 2;

interface InviteToCaseModalProps {
  readonly onClose: () => void;
}

function InviteToCaseModal({ onClose }: InviteToCaseModalProps) {
  const { casePermissionsSelected, getUserRoleCase } = useUserOrgCase();
  const { isLoading, setIsLoading } = useLoading();
  const { displayToaster } = useToast();
  const {
    activeStep,
    setActiveStep,
    updateUsersPayload,
    selectedUsersToInvite,
    setSelectedUsersToInvite,
    setUpdateUsersPayload,
  } = useInviteToCase();

  const { isSmUp } = useBreakpoints();

  const { mutateAsync: createUserRole } = useCreateUserCaseRole();

  const handleNextBtn = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep, setActiveStep]);

  const handlePreviousBtn = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep, setActiveStep]);

  const handleClose = useCallback(() => {
    setActiveStep(1);
    setSelectedUsersToInvite([]);
    onClose();
  }, [onClose, setActiveStep, setSelectedUsersToInvite]);

  const handleSubmit = useCallback(async () => {
    if (!casePermissionsSelected) return;

    try {
      setIsLoading(true);

      const requests = updateUsersPayload.map((user) =>
        createUserRole({
          accountId: user.accountID,
          caseId: casePermissionsSelected.caseID,
          roleName: user.roleName,
        }),
      );

      await Promise.all(requests);

      setActiveStep(activeStep + 1);

      displayToaster('Invitation sent successfully', 'success');
    } catch (error) {
      displayToaster(
        `An error occurred while sending the invitation: ${error}`,
        'error',
      );
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }, [
    activeStep,
    casePermissionsSelected,
    createUserRole,
    displayToaster,
    handleClose,
    setActiveStep,
    setIsLoading,
    updateUsersPayload,
  ]);

  const initialUsers = useMemo(
    () =>
      selectedUsersToInvite.map((user) => ({
        ...user,
        roleName: UserRole.Viewer,
      })),
    [selectedUsersToInvite],
  );

  const users = useMemo(
    () => (updateUsersPayload.length > 0 ? updateUsersPayload : initialUsers),
    [initialUsers, updateUsersPayload],
  );

  const handleRoleChange = useCallback(
    (index: number, event: SelectChangeEvent<unknown>) => {
      const newRole = event.target.value;
      const updatedUsersCopy: OrganizationAccount[] = [...users];

      updatedUsersCopy[index] = {
        ...updatedUsersCopy[index],
        roleName: newRole as UserRole,
      };
      setUpdateUsersPayload(updatedUsersCopy);
    },
    [setUpdateUsersPayload, users],
  );

  const rows = useMemo(() => {
    const userCaseRole = getUserRoleCase() as UserRole;
    const userRoleOptions = getUserRoleSelectionItems(userCaseRole, 'case');
    const options = [UNASSIGNED_ROLE, ...userRoleOptions];
    const usersRows = users.map(
      ({ accountID, firstName, lastName }, index) => ({
        rowId: accountID,
        label: `${firstName} ${lastName}`,
        showAvatar: true,
        secondaryAction: (
          <StyledListItemSelect
            defaultValue={UNASSIGNED_ROLE.key}
            iconComponent={ChevronDownIcon}
            onChange={(e: SelectChangeEvent<unknown>) =>
              handleRoleChange(index, e)
            }
            options={options}
          />
        ),
      }),
    );
    return usersRows;
  }, [getUserRoleCase, handleRoleChange, users]);

  const content: ReactNode[] = useMemo(
    () => [
      <StyledStackContainer key="selectUsers">
        <SelectUsersStepContent />
      </StyledStackContainer>,
      <StyledStackContainer key="assignUserRole">
        <RowListWithActions rows={rows} />
      </StyledStackContainer>,
      <ConfirmationStepContent key="confirm" />,
    ],
    [rows],
  );

  const ActionButtons = useMemo(() => {
    if (activeStep === CONFIRMATION_STEP) return null;

    return (
      <StyledBox>
        {activeStep > 0 && (
          <Button
            fullWidth={!isSmUp}
            label="Previous"
            onClick={handlePreviousBtn}
            variant="outlined"
          />
        )}
        <Button
          disabled={selectedUsersToInvite.length === 0}
          fullWidth={!isSmUp}
          label={activeStep === ASSIGN_ROLES_STEP ? 'Add Users' : 'Next'}
          loading={isLoading}
          loadingPosition="start"
          onClick={
            activeStep === ASSIGN_ROLES_STEP ? handleSubmit : handleNextBtn
          }
        />
      </StyledBox>
    );
  }, [
    activeStep,
    handleNextBtn,
    handlePreviousBtn,
    handleSubmit,
    isLoading,
    isSmUp,
    selectedUsersToInvite.length,
  ]);

  return (
    <Modal
      actionButtons={ActionButtons}
      modalClose={handleClose}
      onClose={handleClose}
      open
      title="Add Users to Case"
    >
      <StepperWrapper activeStep={activeStep} alternativeLabel steps={steps} />
      <Stack padding={Spacing.Medium}>{content[activeStep]}</Stack>
    </Modal>
  );
}

export default function InviteToCaseModalWrapper({
  onClose,
}: {
  readonly onClose: () => void;
}) {
  return (
    <InviteToCaseProvider>
      <InviteToCaseModal onClose={onClose} />
    </InviteToCaseProvider>
  );
}
