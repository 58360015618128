export const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 2,
  style: 'currency',
});

export const currencyFormatterInteger = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 0,
  style: 'currency',
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  style: 'decimal',
});

export const numberFormatterNoDecimals = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  style: 'decimal',
});

const MILLION = 1_000_000;
const THOUSAND = 1_000;

const formatNumber = (value: number, unit: string, isCurrency: boolean) => {
  const formattedValue = Number(
    numberFormatter.format(isCurrency ? value / 100 : value),
  ).toFixed(2);
  return `${isCurrency ? '$' : ''}${formattedValue.substring(0, formattedValue.length - 1)}${unit}`;
};

export const numberFormatterWithUnits = (
  number: number,
  isCurrency: boolean = false,
) => {
  const divisor = isCurrency ? 100 : 1;
  if (number / divisor >= MILLION) {
    return formatNumber(number / MILLION, 'M', isCurrency);
  }
  if (number / divisor >= THOUSAND) {
    return formatNumber(number / THOUSAND, 'k', isCurrency);
  }
  return isCurrency
    ? currencyFormatter.format(number / 100)
    : numberFormatter.format(number);
};
