import {
  ListItem as ListItemMui,
  ListItemProps as ListItemPropsMui,
} from '@mui/material';
import React from 'react';

export type ListItemProps = ListItemPropsMui;

export function ListItem({ ...rest }: ListItemProps) {
  return <ListItemMui {...rest} />;
}
