import { OrganizationAccount } from 'services/ClaimscoreApiService/Organizations/types';

export const DESCRIPTION_BLOCK =
  'Assign roles and manage team members involved in the case.';

export const INSTRUCTION_BLOCK =
  'To invite new users to this team who are outside the organization, or edit existing roles for this case, please visit the permissions page.';

export const users = [
  {
    accountID: '1',
    accountStatus: 'active',
    roleName: 'viewer',
    email: 'jonsnow@winterfell.com',
    phone: '666666666',
    firstName: 'Jon',
    lastName: 'Snow',
  },
  {
    accountID: '2',
    accountStatus: 'pending_2fa',
    roleName: 'admin',
    email: 'khaleesi@queenofdragons.com',
    phone: '666666666',
    firstName: 'Dae',
    lastName: 'Targaryen',
  },
  {
    accountID: '3',
    accountStatus: 'active',
    roleName: 'viewer',
    email: 'sansa@thenorth.com',
    phone: '666666666',
    firstName: 'Sansa',
    lastName: 'Stark',
  },
] as unknown as OrganizationAccount[];
