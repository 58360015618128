import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGetCaseAccounts } from '@hooks/react-query/queries/useGetCaseAccounts';
import { useGetOrganizationAccounts } from '@hooks/react-query/queries/useGetOrganizationAccounts';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Checkbox,
  CheckCircleIcon,
  Field,
  FieldTypes,
  ListItemButton,
  ListItemIcon,
  Loader,
  PlusIcon,
  SearchIcon,
  Stack,
  Typography,
} from 'ui';
import { ColorGreen600 } from 'ui/src/design-system/colors';
import { RowListWithActions } from '@components/RowListWithActions';
import {
  StyledList,
  StyledListItem,
} from '@components/RowListWithActions/RowListWithActions.styles';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { useInfiniteScroll } from '@hooks/customHooks/useInfiniteScroll';
import { users } from '@pages/CaseSettings/components/CaseTeam/utils';
import { getArrayDifferenceByProperty } from '@utils/arrayDifference';
import { OrganizationAccount } from 'services/ClaimscoreApiService/Organizations/types';
import { useInvitationsModalOrchestrator } from '../../InvitationModalsOrchestrator';
import { SelectedUsers, useInviteToCase } from '../InviteToCaseContext';
import {
  StyledBox,
  StyledButton,
  StyledFontTypography,
  StyledIcon,
  StyledItemLoader,
} from './SelectUsersStepContent.styles';

export function SelectUsersStepContent() {
  const [searchQuery, setSearchQuery] = useState('');
  const { organizationSelected, casePermissionsSelected } = useUserOrgCase();
  const [searchParams] = useSearchParams();

  const { setInviteUserModalOpen, setInviteOrgModalOpen } =
    useInvitationsModalOrchestrator();

  const { setSelectedUsersToInvite } = useInviteToCase();

  const handleInviteToOrg = () => {
    setInviteUserModalOpen(false);
    setInviteOrgModalOpen(true);
  };

  const listRef = useRef<HTMLUListElement>(null);

  const [checkedUsers, setCheckedUsers] = useState<SelectedUsers[]>([]);
  const [usersToDisplay, setUsersToDisplay] = useState<SelectedUsers[]>([]);

  const handleToggle = useCallback(
    (value: SelectedUsers) => () => {
      setCheckedUsers((prevChecked) => {
        const index = prevChecked.findIndex(
          (user) => user.accountID === value.accountID,
        );
        if (index !== -1) {
          return prevChecked.filter(
            (user) => user.accountID !== value.accountID,
          );
        }
        return [...prevChecked, { ...value, checked: true }];
      });
    },
    [],
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 12,
    page: 0,
  });

  const { data: organizationAccounts, isLoading } = useGetOrganizationAccounts({
    organizationId:
      searchParams.get('orgId') || organizationSelected?.organizationID || '',
    pageNumber: paginationModel.page,
    search: searchQuery,
  });

  const { data: caseAccounts } = useGetCaseAccounts({
    caseId: casePermissionsSelected?.caseID || '',
    pageNumber: paginationModel.page,
    search: searchQuery,
  });

  useEffect(() => {
    if (organizationAccounts?.page && caseAccounts?.page) {
      const uninvitedUsers = getArrayDifferenceByProperty<OrganizationAccount>(
        'accountID',
        organizationAccounts.page,
        caseAccounts.page,
      );

      const mergedUsers: SelectedUsers[] = uninvitedUsers.map((invite) => {
        const isChecked = checkedUsers.some(
          (checked) => checked.accountID === invite.accountID,
        );
        return {
          ...invite,
          checked: isChecked,
        };
      });

      if (searchQuery.trim() === '') {
        const allUsers = [
          ...checkedUsers.filter(
            (checked) =>
              !mergedUsers.some((user) => user.accountID === checked.accountID),
          ),
          ...mergedUsers,
        ];

        setUsersToDisplay(
          allUsers.sort((a, b) => (b.checked ? 1 : 0) - (a.checked ? 1 : 0)),
        );
      } else {
        setUsersToDisplay(mergedUsers);
      }
      setSelectedUsersToInvite(checkedUsers);
    }
  }, [
    caseAccounts?.page,
    organizationAccounts?.page,
    searchQuery,
    checkedUsers,
    setUsersToDisplay,
    setSelectedUsersToInvite,
  ]);

  const handleScroll = useCallback(() => {
    if (organizationAccounts && !isLoading) {
      setPaginationModel((prevPage) => ({
        pageSize: prevPage.pageSize,
        page: Math.min(prevPage.page + 1, organizationAccounts.totalPages),
      }));
    }
  }, [isLoading, organizationAccounts]);

  useInfiniteScroll(listRef, handleScroll);

  const rows = useMemo(() => {
    const usersRows = usersToDisplay?.map((user) => ({
      rowId: user.accountID,
      label: `${user.firstName} ${user.lastName}`,
      showAvatar: true,
      onClick: handleToggle(user),
      secondaryAction: (
        <StyledBox>
          <Checkbox
            checked={user.checked}
            checkedIcon={<CheckCircleIcon />}
            icon={<Box />}
            label=""
            onChange={() => handleToggle(user)}
          />
        </StyledBox>
      ),
    }));
    return usersRows;
  }, [handleToggle, usersToDisplay]);

  return (
    <Stack>
      <Stack>
        <Field
          iconLeft={<SearchIcon />}
          label="Search"
          onChange={(event) => setSearchQuery(event.target.value)}
          type={'text' as FieldTypes}
          value={searchQuery}
        />
      </Stack>
      <Stack>
        <StyledList data-testid="list-container" ref={listRef}>
          {users?.length > 0 ? (
            <Stack>
              <RowListWithActions key="selectUsers" rows={rows} />
              <StyledListItem onClick={handleInviteToOrg}>
                <ListItemButton>
                  <ListItemIcon>
                    <PlusIcon color={ColorGreen600} />
                  </ListItemIcon>
                  <StyledFontTypography color="primary" variant="body">
                    Add New ClaimScore User
                  </StyledFontTypography>
                </ListItemButton>
              </StyledListItem>
              {isLoading ? (
                <StyledItemLoader>
                  <Loader />
                </StyledItemLoader>
              ) : null}
            </Stack>
          ) : (
            <StyledButton onClick={handleInviteToOrg}>
              <StyledIcon color={ColorGreen600} />
              <Typography variant="subtitle">Add to Organization</Typography>
              <Box>
                <Typography color="text.secondary">
                  Can't find who you're looking for?
                </Typography>
                <Typography color="text.secondary">
                  Invite them to your organization.
                </Typography>
              </Box>
            </StyledButton>
          )}
        </StyledList>
      </Stack>
    </Stack>
  );
}
