import { Select, SelectProps as SelectPropsMui } from '@mui/material';
import React from 'react';
import { StyledMenuItem } from '../Field/Field.styles';

export type SelectProps = SelectPropsMui & {
  readonly iconComponent: React.ElementType;
  readonly options: { key: string; value: string }[];
};

const listSelectStyles = {
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '.MuiSelect-icon': {
    color: 'primary.main',
    right: 0,
    top: 'calc(50% - 0.7em)',
  },
  color: 'primary.main',
  fontWeight: 'bold',
};

export function ListSelect({
  onChange,
  iconComponent,
  value,
  options,
  ...rest
}: SelectProps) {
  return (
    <Select
      IconComponent={iconComponent}
      inputProps={{ 'aria-label': 'List Select' }}
      onChange={onChange}
      sx={listSelectStyles}
      value={value}
      {...rest}
    >
      {options.map((option) => (
        <StyledMenuItem key={option.key} value={option.key}>
          {option.value}
        </StyledMenuItem>
      ))}
    </Select>
  );
}
