import { FormikValues, useFormik } from 'formik';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useInviteToOrganization } from '@hooks/react-query/mutations/useInviteToOrganization';
import {
  Box,
  Button,
  ChevronDownIcon,
  Divider,
  Field,
  FieldTypes,
  Modal,
  SelectChangeEvent,
  Spacing,
  Stack,
  styled,
  Typography,
  useBreakpoints,
  useToast,
} from 'ui';
import { RowListWithActions } from '@components/RowListWithActions';
import {
  DropdownType,
  getUserRoleSelectionItems,
  UNASSIGNED_ROLE,
  UserRoleDropdown,
} from '@components/UserRoleDropdown';
import { useLoading } from '@context/LoadingContext';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { InviteToOrganizationPayload } from 'services/ClaimscoreApiService/Invitations/types';
import { CasesPermissions } from 'services/ClaimscoreApiService/Permissions/types';
import { UserRole } from 'services/ClaimscoreApiService/shared/types';
import { StyledListItemSelect } from '../InviteToCaseModal/InviteToCaseModal';
import { StyledButtonContent } from './InviteToOrgModal.styles';
import { ADD_NEW_USER_CONTAINER } from './InviteToOrgModal.testIds';
import { validationSchema } from './utils';

interface RenderFieldProps {
  readonly name: string;
  readonly label: string;
  readonly type?: FieldTypes;
  readonly disabled?: boolean;
}

interface InviteToOrgModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
}

const StyledStack = styled(Stack)`
  display: flex;
  gap: 16px;
  width: 600px;
  min-height: 250px;
  @media (max-width: 768px) {
    width: 380px;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

const StyledBoxContainer = styled(Box)`
  height: 450px;
`;

enum InviteToOrgSteps {
  BasicInformation,
  AssignCases,
}

export function InviteToOrgModal({ open, onClose }: InviteToOrgModalProps) {
  const { displayToaster } = useToast();
  const { isLoading, setIsLoading } = useLoading();
  const { isSmUp } = useBreakpoints();
  const { organizationSelected, userCases, getUserRoleCase } = useUserOrgCase();
  const [selectedView, setSelectedView] = useState(
    InviteToOrgSteps.BasicInformation,
  );
  const [casesToRender, setCasesToRender] =
    useState<CasesPermissions[]>(userCases);
  const [updatedCases, setUpdatedCases] = useState<CasesPermissions[]>([]);

  const { mutateAsync: inviteToOrganization } = useInviteToOrganization();

  const handleFormSubmit = async (values: InviteToOrganizationPayload) => {
    try {
      setIsLoading(true);
      // TODO: Implement the inviteToOrganization with cases and roles when BE is ready
      await inviteToOrganization({
        ...values,
        organizationID: organizationSelected.organizationID,
        // TODO: Add cases and roles when BE is ready
        // updatedCases,
      });
      // eslint-disable-next-line no-console
      console.log('updatedCases', updatedCases);
      displayToaster('Invitation sent successfully', 'success');
    } catch (error) {
      displayToaster(
        `An error occurred while sending the invitation: ${error}`,
        'error',
      );
    } finally {
      handleOnClose();
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      companyName: organizationSelected?.organizationName,
      roleName: '' as UserRole,
      organizationID: '',
    },
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  const handleDropdownChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue('roleName', event.target.value as UserRole);
    },
    [formik],
  );

  const handleRoleChange = useCallback(
    (
      index: number,
      event: SelectChangeEvent<unknown>,
      caseData: CasesPermissions,
    ) => {
      const newRole = event.target.value as UserRole;

      setCasesToRender((prevCases) =>
        prevCases.map((prevCase, i) =>
          i === index ? { ...prevCase, roleName: newRole } : prevCase,
        ),
      );

      setUpdatedCases((prevCases) =>
        prevCases.map((prevCase) =>
          prevCase.caseID === caseData.caseID
            ? { ...prevCase, roleName: newRole }
            : prevCase,
        ),
      );
    },
    [setCasesToRender, setUpdatedCases],
  );

  const cases = useMemo(() => {
    const userCaseRole = getUserRoleCase() as UserRole;
    const userRoleOptions = getUserRoleSelectionItems(userCaseRole, 'org');
    const options = [UNASSIGNED_ROLE, ...userRoleOptions];
    return casesToRender?.map((userCase, index) => ({
      rowId: userCase.caseID,
      label: userCase.caseName,
      secondaryAction: (
        <StyledListItemSelect
          defaultValue={UNASSIGNED_ROLE.key}
          iconComponent={ChevronDownIcon}
          onChange={(e: SelectChangeEvent<unknown>) =>
            handleRoleChange(index, e, userCase)
          }
          options={options}
        />
      ),
    }));
  }, [casesToRender, getUserRoleCase, handleRoleChange]);

  const renderFormFields = useCallback(
    ({ touched, errors, handleChange, handleBlur, values }: FormikValues) => {
      const renderField = ({
        name,
        label,
        type = FieldTypes.text,
        disabled = false,
      }: RenderFieldProps) => (
        <Field
          disabled={disabled}
          errorMsg={(touched[name] && errors[name]) || undefined}
          label={label}
          menuItems={
            name === 'companyName'
              ? [
                  {
                    key: organizationSelected?.organizationName || '',
                    value: organizationSelected?.organizationName || '',
                  },
                ]
              : undefined
          }
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          type={type}
          value={values[name]}
        />
      );

      if (selectedView === InviteToOrgSteps.BasicInformation) {
        return (
          <>
            <StyledStack padding={Spacing.Medium}>
              <Typography variant="subtitle">Basic Information</Typography>
              <Typography color="textSecondary" variant="caption">
                Enter the user’s first name, last name, and email to set up
                their profile.
              </Typography>
              <StyledBox>
                {renderField({ name: 'firstName', label: 'First Name' })}
                {renderField({ name: 'lastName', label: 'Last Name' })}
              </StyledBox>
              {renderField({ name: 'email', label: 'Email' })}
            </StyledStack>
            <Divider />
            <StyledStack padding={Spacing.Medium}>
              <Typography variant="subtitle">Organization</Typography>
              <Typography color="textSecondary" variant="caption">
                Select the user's company and assign their role within the
                organization.
              </Typography>
              {renderField({
                name: 'companyName',
                label: 'Company Name',
                type: FieldTypes.select,
                disabled: true,
              })}
              <UserRoleDropdown
                dropdownType={'organization' as DropdownType}
                errorMsg={
                  (touched['Organization Role'] &&
                    errors['Organization Role']) ||
                  undefined
                }
                name="roleName"
                onChange={handleDropdownChange}
                value={values.roleName || ''}
              />
            </StyledStack>
          </>
        );
      }

      return (
        <StyledStack padding={Spacing.Medium}>
          <Typography variant="subtitle">Assign Case Roles</Typography>
          <Typography color="textSecondary" variant="caption">
            Select a role for this user in any applicable cases.
          </Typography>
          <StyledBoxContainer>
            <RowListWithActions key="assignCaseRole" rows={cases} />
          </StyledBoxContainer>
        </StyledStack>
      );
    },
    [
      cases,
      handleDropdownChange,
      organizationSelected?.organizationName,
      selectedView,
    ],
  );

  const handleContinueClick = useCallback(() => {
    setSelectedView(InviteToOrgSteps.AssignCases);
  }, []);

  const handleOnClose = useCallback(() => {
    formik.resetForm();
    setCasesToRender(userCases);
    setUpdatedCases([]);
    setSelectedView(InviteToOrgSteps.BasicInformation);
    onClose();
  }, [formik, onClose, userCases]);

  const actionButtonGroup = useMemo(() => {
    if (selectedView === InviteToOrgSteps.BasicInformation) {
      return (
        <StyledButtonContent>
          <Button
            disabled={Object.keys(formik.errors).length > 0 || !formik.dirty}
            fullWidth={!isSmUp}
            id="continue-add-new-user-button"
            label="Continue"
            onClick={handleContinueClick}
          />
        </StyledButtonContent>
      );
    }

    return (
      <StyledButtonContent>
        <Button
          fullWidth={!isSmUp}
          id="previous-add-new-user"
          label="Previous"
          onClick={() => setSelectedView(InviteToOrgSteps.BasicInformation)}
          variant="outlined"
        />
        <Button
          fullWidth={!isSmUp}
          label="Send Invite"
          loading={isLoading}
          loadingPosition="start"
          onClick={() => formik.handleSubmit()}
          type="submit"
        />
      </StyledButtonContent>
    );
  }, [formik, handleContinueClick, isLoading, isSmUp, selectedView]);

  return (
    <Modal
      actionButtons={actionButtonGroup}
      data-testid={ADD_NEW_USER_CONTAINER}
      modalClose={onClose}
      onClose={handleOnClose}
      open={open}
      title="Invite New User"
    >
      {renderFormFields(formik)}
    </Modal>
  );
}
