import * as React from 'react';
import { Stack, Step, Typography } from 'ui';
import {
  StepperContainer,
  StyledStepLabel,
  StyledStepper,
} from './StepperWrapper.styles';
import { Orientation, StepsProps } from './type';

interface StepperProps {
  readonly steps: StepsProps[];
  readonly activeStep: number;
  readonly orientation?: Orientation;
  readonly alternativeLabel?: boolean;
}

export function StepperWrapper({
  steps,
  activeStep,
  alternativeLabel = false,
  orientation = 'horizontal',
}: StepperProps) {
  return (
    <StepperContainer>
      <StyledStepper
        activeStep={activeStep}
        alternativeLabel={alternativeLabel}
        orientation={orientation}
      >
        {steps.map(({ label, description }: StepsProps) => (
          <Step key={label}>
            <StyledStepLabel>
              <Stack direction="column">
                <Typography color="text.primary" variant="caption">
                  {label}
                </Typography>
                {description ? (
                  <Typography color="text.secondary" variant="caption">
                    {description}
                  </Typography>
                ) : null}
              </Stack>
            </StyledStepLabel>
          </Step>
        ))}
      </StyledStepper>
    </StepperContainer>
  );
}
