import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Flags,
  MenuIcon,
  Spacing,
  Stack,
  styled,
  TopNavbar,
  useBreakpoints,
  useFlag,
} from 'ui';
import { PageHeaderPortal } from '@components/PageHeaderTitle/PageHeaderTitle';
import { PATHS } from '@utils/paths';
import { AddOptionsButton } from './Buttons/AddOptionsButton';
import { MyAccountButton } from './Buttons/MyAccountButton';

const StyledNavbar = styled(TopNavbar, {
  shouldForwardProp: (prop) =>
    ['leftToolbar', 'rightToolbar'].includes(String(prop)),
})<{ hasSidebar?: boolean }>`
  box-shadow: 0px 0px 0px rgba(100, 116, 139, 0.16);
  width: ${({ theme, hasSidebar }) =>
    hasSidebar
      ? `calc(100% - ${theme.constants.navigationDrawerMinimizedWidth})`
      : undefined};
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: ${({ theme }) => theme.palette.grey[200]};
`;

interface MainTopNavbarProps {
  readonly toggleSidebar?: () => void;
}

const SIDE_MENU_BTN_LABEL = 'Open Side Navigation Drawer';

export function MainTopNavbar({ toggleSidebar }: MainTopNavbarProps) {
  const { isMdUp } = useBreakpoints();
  const { pathname } = useLocation();
  const isCaseSettingsEnabled = useFlag(Flags.caseSettings);

  const hasSideBar = useMemo(
    () => isMdUp && pathname !== PATHS.PRIVATE_BASE.root,
    [isMdUp, pathname],
  );

  const shouldShowSideMenuButton = useMemo(
    () => !isMdUp && pathname !== PATHS.PRIVATE_BASE.root,
    [isMdUp, pathname],
  );

  const getLeftToolbar = () => (
    <>
      {shouldShowSideMenuButton ? (
        <Button
          aria-label="Open Side Navigation Drawer"
          color="neutral"
          mr={Spacing.Small}
          onClick={toggleSidebar}
          startIcon={
            <MenuIcon
              aria-label={SIDE_MENU_BTN_LABEL}
              title={SIDE_MENU_BTN_LABEL}
            />
          }
          variant="text"
        />
      ) : null}
      <PageHeaderPortal />
    </>
  );

  const getRightToolbar = () => (
    <Stack direction="row" spacing={Spacing.Small}>
      {isCaseSettingsEnabled ? <AddOptionsButton /> : null}
      <MyAccountButton />
    </Stack>
  );

  return (
    <StyledNavbar
      hasSidebar={hasSideBar}
      leftToolbar={getLeftToolbar()}
      logoVariant="none"
      rightToolbar={getRightToolbar()}
    />
  );
}
