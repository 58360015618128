import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Button } from '../Button';
import { Field } from '../Field';
import { Stack } from '../Stack';

export const InputContainer = styled(Field)`
  margin: 0;
  border-width: 0;
  border-radius: unset;
  max-width: 260px;
  max-height: 56px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  :hover {
    border: unset;
  }
  .MuiInputBase-root {
    border-radius: unset;
    border: unset;
  }
  .MuiOutlinedInput-input {
    cursor: default;
    :hover {
      border-color: ${({ theme }) => theme.palette.neutral[200]};
    }
    padding: ${({ theme }) => theme.spacing(Spacing.Medium)};
    border: 1px solid ${({ theme }) => theme.palette.neutral[200]};
    border-left: unset;
    border-right: unset;
    text-align: center;
  }

  :hover .MuiInputBase-root input:not(:active) {
    border-color: ${({ theme }) => theme.palette.neutral[200]};
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
  :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.neutral[200]};
  }
  :hover .MuiOutlinedInput-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.primary.main};
  :first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.neutral[200]};
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral[200]};
    border-right: unset;
    border-left-color: ${({ theme }) => theme.palette.neutral[200]};
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
  :last-of-type {
    border-right-color: ${({ theme }) => theme.palette.neutral[200]};
    border-top: 1px solid ${({ theme }) => theme.palette.neutral[200]};
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral[200]};
    border-left: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }
`;

export const GroupContainer = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
