import React from 'react';
import {
  ColorGreen200,
  ColorGreen600,
  ColorRed200,
  ColorRed600,
  ColorYellow200,
  ColorYellow800,
} from '../../design-system/colors';
import { Typography } from '../Typography';

export const statusBarItemsMapper = {
  editable: {
    label: 'Editable',
    backgroundColor: ColorGreen200,
    text: (
      <Typography color="text.secondary">
        Until <strong>Claim Submission Start Date </strong>
        on November 20, 2024.
      </Typography>
    ),
    color: ColorGreen600,
    mobileText: <Typography color="text.secondary">11/20/2024</Typography>,
    tooltipText: (
      <Typography variant="caption">
        All settings can be modified before the submission period starts.
      </Typography>
    ),
  },
  limited: {
    label: 'Limited',
    backgroundColor: ColorYellow200,
    text: (
      <Typography color="text.secondary">
        Until <strong>Claim Submission End Date </strong>
        on November 20, 2024.
      </Typography>
    ),
    mobileText: <Typography color="text.secondary">11/21/2024</Typography>,
    color: ColorYellow800,
    tooltipText: (
      <Typography variant="caption">
        Some non-critical settings can be edited until the submission period
        ends.
      </Typography>
    ),
  },
  locked: {
    label: 'Locked',
    backgroundColor: ColorRed200,
    text: (
      <Typography color="text.secondary">
        Contact us to make any changes
      </Typography>
    ),
    mobileText: '',
    color: ColorRed600,
    tooltipText: (
      <Typography variant="caption">
        No changes are allowed after the submission period ends. If you must
        make changes, please contact your ClaimScore admin.
      </Typography>
    ),
  },
};
