import { Tooltip as TooltipMui } from '@mui/material';
import { tooltipClasses as tooltipClassesMui } from '@mui/material/Tooltip';
import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { TooltipProps } from './Tooltip';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <TooltipMui {...props} classes={{ popper: className }} />
))`
  & .${tooltipClassesMui.tooltip} {
    max-width: 256px;
    padding: ${({ theme }) => theme.spacing(Spacing.Small)};
    border-radius: ${({ theme }) => theme.borderRadii.outer};
  }
`;
