import { useMutation } from 'react-query';
import { inviteToOrganization } from 'services/ClaimscoreApiService/Invitations/Invitations.service';
import type {
  InviteToOrganizationPayload,
  InviteToOrganizationResponse,
} from 'services/ClaimscoreApiService/Invitations/types';

const MUTATION_KEY = 'inviteToOrganization';

export const useInviteToOrganization = () =>
  useMutation<InviteToOrganizationResponse, Error, InviteToOrganizationPayload>(
    MUTATION_KEY,
    (payload: InviteToOrganizationPayload) => inviteToOrganization(payload),
  );
