import { List, ListItem, Spacing, Stack, styled } from 'ui';

export const StyledStack = styled(Stack)`
  align-items: flex-start;
  width: 100%;
`;

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'noRoundCorners' && prop !== 'readOnly',
})<{ noRoundCorners?: boolean; isReadOnly?: boolean }>`
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  padding: ${({ theme, isReadOnly }) =>
    isReadOnly ? theme.spacing(Spacing.Small, Spacing.Medium) : 0};
  padding-right: ${({ theme, isReadOnly }) =>
    isReadOnly ? theme.spacing(Spacing.XXLarge) : 0};
  border-color: ${({ theme }) => theme.palette.neutral[200]};
  background-color: ${({ theme }) => theme.palette.background.paper};
  :first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.neutral[200]};
    border-top-right-radius: ${({ theme, noRoundCorners }) =>
      noRoundCorners ? 'unset' : theme.borderRadii.outer};
    border-top-left-radius: ${({ theme, noRoundCorners }) =>
      noRoundCorners ? 'unset' : theme.borderRadii.outer};
  }
  :last-of-type {
    border-color: ${({ theme }) => theme.palette.neutral[200]};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadii.outer};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadii.outer};
  }
  .MuiButtonBase-root {
    padding: ${({ theme }) => theme.spacing(Spacing.Small, Spacing.Medium)};
    padding-right: ${({ theme }) => theme.spacing(Spacing.XXLarge)};
  }
  .MuiButtonBase-root:hover {
    height: 55px;
    background-color: ${({ theme, isReadOnly }) =>
      isReadOnly ? theme.palette.background.paper : theme.palette.neutral[200]};
  }
  overflow-y: none;
  height: 55px;
`;

export const StyledList = styled(List)`
  width: 100%;
`;
