import { capitalizeFirstLetter } from '@utils/strings';
import { InvitationType } from 'services/ClaimscoreApiService/Invitations/types';
import { UserRole } from 'services/ClaimscoreApiService/shared/types';

export const getUserRoleSelectionItems = (
  roleName: UserRole,
  dropdownType: InvitationType,
) => {
  const userRoles = [...Object.values(UserRole)];

  let userRoleSelectionItems = userRoles.map((role) => ({
    key: role,
    value: capitalizeFirstLetter(role),
  }));

  if (dropdownType === ('org' as InvitationType)) {
    userRoleSelectionItems = userRoleSelectionItems.filter(
      (userRole) => userRole.key !== UserRole.Reviewer,
    );
  }

  const roleIndex = userRoleSelectionItems.findIndex(
    (item) => item.key === roleName,
  );

  if (roleIndex === -1) {
    return [];
  }

  return userRoleSelectionItems.slice(roleIndex + 1);
};

export const UNASSIGNED_ROLE = {
  key: 'unassigned',
  value: 'Unassigned',
};
