import { styled } from '@mui/material';
import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { numberFormatterWithUnits } from '../../utils/number-formatter';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import { SummaryCardField } from './SummaryCardField';

const StyledTypography = styled(Typography)`
  flex: 1;
  text-align: end;
`;

export const StyledStackContainer = styled(Stack)`
  border-radius: ${({ theme }) => theme.borderRadii.inner};
  border: 1px solid;
  border-bottom: none;
  border-color: ${({ theme }) => theme.palette.neutral[200]};
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Medium)};
  width: 100%;
  flex-direction: column;
`;

export const StyledItemStack = styled(Stack)`
  justify-content: space-between;
  width: 100%;
`;
export const StyledValueStack = styled(Stack)`
  justify-content: space-between;
  flex: 2;
  flex-direction: row;
`;

type ValueFormat = 'number' | 'currency';
interface SummaryCardGroupFieldsProps {
  readonly items: {
    key: string;
    value?: number | string;
    color?: string;
    percentage?: number;
    isBold?: boolean;
  }[];
  readonly headerLabel?: string;
  readonly headerContent?: React.ReactNode;
  readonly format?: ValueFormat;
}

const calculateFinalValue = (value: number | string, format: ValueFormat) => {
  if (typeof value === 'string') {
    return value;
  }
  return format === 'number'
    ? numberFormatterWithUnits(value)
    : numberFormatterWithUnits(value, true);
};

export function SummaryCardGroupFields({
  items,
  headerLabel,
  headerContent,
  format = 'number',
}: SummaryCardGroupFieldsProps) {
  return (
    <StyledStackContainer>
      {headerLabel && headerContent ? (
        <SummaryCardField label={headerLabel}>{headerContent}</SummaryCardField>
      ) : null}
      {items.map(({ key, value, color, percentage, isBold = false }) => {
        return (
          <SummaryCardField
            color={color}
            key={`stack-${key}${value}`}
            label={key}
          >
            <StyledValueStack>
              {value != null && (
                <StyledTypography variant={isBold ? 'bodyStrong' : 'body'}>
                  {calculateFinalValue(value, format)}
                </StyledTypography>
              )}

              {percentage != null && (
                <StyledTypography color="text.secondary" variant="body">
                  {percentage}%
                </StyledTypography>
              )}
            </StyledValueStack>
          </SummaryCardField>
        );
      })}
    </StyledStackContainer>
  );
}
