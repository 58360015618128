import {
  SwipeableDrawer as SwipeableDrawerMui,
  SwipeableDrawerProps as SwipeableDrawerPropsMui,
} from '@mui/material';
import React from 'react';
import { styled } from '../../utils/styled';
import { themeMediaQuery } from '../../utils/themeMediaQuery';

export type SwipeableDrawerProps = SwipeableDrawerPropsMui;

export const StyledSwipeableDrawer = styled(SwipeableDrawerMui)`
  .MuiModal-backdrop {
    background-color: #fff0;
  }

  .MuiDrawer-paper {
    border-top: 0;
    border-left: 0;
    box-shadow: ${({ theme }) => theme.boxShadow};
    width: 100vw;
    border-top-right-radius: ${({ theme }) => theme.borderRadii.mobileDrawer};
    border-top-left-radius: ${({ theme }) => theme.borderRadii.mobileDrawer};

    ${({ theme }) => themeMediaQuery(theme, 'md')`
      width: 600px;
      height: calc(100vh - 144px);
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      top: 145px;
    `}
  }
`;

export function SwipeableDrawer({ children, ...rest }: SwipeableDrawerProps) {
  return <StyledSwipeableDrawer {...rest}>{children}</StyledSwipeableDrawer>;
}
