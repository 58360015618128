import toQueryParams from '../../utils/toQueryParams';
import claimScoreApiServiceClient from '../client';
import {
  CreateOrganizationPayload,
  OrganizationAccountsResponse,
  OrganizationActiveSearchResultsResponse,
  OrganizationCasesResponse,
  OrganizationIdResponse,
  OrganizationInfoResponse,
} from './types';

export const getOrganizationInfo = async (
  organizationId: string,
): Promise<OrganizationInfoResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/organizations/${organizationId}`,
  );

  return data;
};

export const getOrganizationAccounts = async ({
  accountStatus,
  accountRole,
  organizationId,
  pageNumber,
  search,
}: {
  organizationId: string;
  pageNumber: number;
  accountRole?: string;
  accountStatus?: string;
  search?: string;
}): Promise<OrganizationAccountsResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/organizations/${organizationId}/accounts?${toQueryParams({
      accountRole,
      accountStatus,
      pageNumber,
      pageSize: 12,
      search,
    })}`,
  );

  return data;
};

export const getOrganizationCases = async (
  organizationId: string,
  pageNumber: number,
): Promise<OrganizationCasesResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/organizations/${organizationId}/cases?${toQueryParams({
      pageNumber,
      pageSize: 12,
    })}`,
  );

  return data;
};

export const getOrganizationActiveSearchResults = async (
  searchValue: string,
): Promise<OrganizationActiveSearchResultsResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/organizations/activeSearch/${searchValue}`,
  );

  return data;
};

export const createOrganization = async (
  organization: CreateOrganizationPayload,
): Promise<OrganizationIdResponse> => {
  const { data } = await claimScoreApiServiceClient.post('/organizations', {
    organization,
  });

  return data;
};

export const deleteOrganization = async (
  organizationId: string,
): Promise<OrganizationIdResponse> => {
  const { data } = await claimScoreApiServiceClient.delete(
    `/organizations/${organizationId}`,
  );

  return data;
};
