import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { OrganizationAccount } from 'services/ClaimscoreApiService/Organizations/types';

export interface SelectedUsers extends OrganizationAccount {
  checked: boolean;
}

interface InviteToCaseContextProps {
  activeStep: number;
  setActiveStep: (value: number) => void;
  selectedUsersToInvite: SelectedUsers[];
  setSelectedUsersToInvite: React.Dispatch<
    React.SetStateAction<SelectedUsers[]>
  >;
  updateUsersPayload: OrganizationAccount[];
  setUpdateUsersPayload: (value: OrganizationAccount[]) => void;
}

const InviteToCaseContext = createContext<InviteToCaseContextProps>({
  activeStep: 0,
  setActiveStep: () => undefined,
  selectedUsersToInvite: [],
  setSelectedUsersToInvite: () => undefined,
  updateUsersPayload: [],
  setUpdateUsersPayload: () => undefined,
});

export function InviteToCaseProvider({ children }: PropsWithChildren) {
  const [activeStep, setActiveStep] = useState(0);

  const [selectedUsersToInvite, setSelectedUsersToInvite] = useState<
    SelectedUsers[]
  >([]);

  const [updateUsersPayload, setUpdateUsersPayload] = useState<
    OrganizationAccount[]
  >([]);
  const memoizedContextValues = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      selectedUsersToInvite,
      setSelectedUsersToInvite,
      updateUsersPayload,
      setUpdateUsersPayload,
    }),
    [
      activeStep,
      setActiveStep,
      selectedUsersToInvite,
      setSelectedUsersToInvite,
      updateUsersPayload,
      setUpdateUsersPayload,
    ],
  );

  return (
    <InviteToCaseContext.Provider value={memoizedContextValues}>
      {children}
    </InviteToCaseContext.Provider>
  );
}

export const useInviteToCase = () => useContext(InviteToCaseContext);
