import React from 'react';
import { ColorNeutral600 } from '../../design-system/colors';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { themeMediaQuery } from '../../utils/themeMediaQuery';
import { IconButton } from '../IconButton';
import { InfoOutlinedIcon } from '../Icons/InfoOutlinedIcon';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const StyledContainerStack = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};

  ${({ theme }) => themeMediaQuery(theme, 'md')`
    justify-content: flex-start;
    margin-right: ${theme.spacing(Spacing.Small)};
  `}
`;

const StyledItemsStack = styled(Stack)`
  width: 100%;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
  justify-content: flex-start;
  ${({ theme }) => themeMediaQuery(theme, 'md')`
    justify-content: space-between;`}
`;

const StyledIconStack = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

interface SummaryCardHeaderProps {
  readonly icon: React.ReactNode;
  readonly title: string;
  readonly onClickInfo?: () => void;
  readonly actionButton?: React.ReactNode;
  readonly noInfoIcon?: boolean;
}

export function SummaryCardHeader({
  icon,
  title,
  onClickInfo,
  actionButton,
  noInfoIcon = false,
}: SummaryCardHeaderProps) {
  return (
    <StyledContainerStack>
      <StyledItemsStack>
        <StyledIconStack>
          {icon}
          <Typography variant="h5">{title}</Typography>
        </StyledIconStack>
        {noInfoIcon ? null : (
          <IconButton aria-label="info" onClick={onClickInfo}>
            <InfoOutlinedIcon color={ColorNeutral600} />
          </IconButton>
        )}
      </StyledItemsStack>
      {actionButton}
    </StyledContainerStack>
  );
}
