import React, { PropsWithChildren, useCallback } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button, CloseIcon, Divider, Spacing, styled, Typography } from 'ui';
import { useAuth } from '@context/AuthContext';
import { UserOrgCaseProvider } from '@context/UserOrgCaseContext';
import { PATHS } from '@utils/paths';

const StyledFullScreenLayout = styled('div')`
  height: 100%;
`;

const StyledHeader = styled('header')`
  display: flex;
  height: ${({ theme }) => theme.spacing(Spacing.Huge)};
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing(Spacing.Medium)}`};
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const StyledButton = styled(Button)`
  gap: ${({ theme }) => theme.spacing(1)};
`;

export function FullScreenLayout({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { pathname, search, state } = useLocation();

  const handleOnCloseClick = useCallback(() => {
    navigate(state?.previousUrl || PATHS.PRIVATE_BASE.dashboard);
  }, [navigate, state?.previousUrl]);

  if (!isAuthenticated) {
    return (
      <Navigate
        state={{ previousUrl: `${pathname}${search}` }}
        to={PATHS.PUBLIC.login}
      />
    );
  }

  return (
    <UserOrgCaseProvider>
      <StyledFullScreenLayout>
        <StyledHeader>
          <StyledButton
            color="neutral"
            onClick={handleOnCloseClick}
            variant="text"
          >
            <CloseIcon />
            <Typography variant="subtitle">Close</Typography>
          </StyledButton>
        </StyledHeader>
        <Divider noMargin />
        {children}
      </StyledFullScreenLayout>
    </UserOrgCaseProvider>
  );
}
