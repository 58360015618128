import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

interface UsersManagementContextProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  timeframeDrawerOpen: boolean;
  setTimeframeDrawerOpen: (value: boolean) => void;
}

const UsersManagementContext = createContext<UsersManagementContextProps>({
  searchQuery: '',
  setSearchQuery: () => undefined,
  timeframeDrawerOpen: false,
  setTimeframeDrawerOpen: () => undefined,
});

export function UsersManagementProvider({ children }: PropsWithChildren) {
  const [searchQuery, setSearchQuery] = useState('');

  const [timeframeDrawerOpen, setTimeframeDrawerOpen] = useState(false);

  const contextValues = useMemo(
    () => ({
      searchQuery,
      setSearchQuery,
      timeframeDrawerOpen,
      setTimeframeDrawerOpen,
    }),
    [searchQuery, timeframeDrawerOpen],
  );

  return (
    <UsersManagementContext.Provider value={contextValues}>
      {children}
    </UsersManagementContext.Provider>
  );
}

export const useUsersManagement = () => useContext(UsersManagementContext);
