import {
  FormControlLabel as FormControlLabelMui,
  FormControlLabelProps as FormControlLabelPropsMui,
} from '@mui/material';
import React from 'react';

export type FormControlLabelProps = FormControlLabelPropsMui;

export function FormControlLabel({ ...rest }: FormControlLabelProps) {
  return <FormControlLabelMui {...rest} />;
}
