import { useQuery } from 'react-query';
import { AccountStatus } from '@shared/types/forms';
import { getOrganizationAccounts } from 'services/ClaimscoreApiService/Organizations';
import type { OrganizationAccountsResponse } from 'services/ClaimscoreApiService/Organizations/types';
import { UserRole } from 'services/ClaimscoreApiService/shared/types';

const QUERY_KEY = 'getOrganizationAccounts';

const DEFAULT_PAGE = 0;

export type UseGetOrganizationAccounts = {
  organizationId: string;
  pageNumber?: number;
  pageSize?: number;
  accountRole?: UserRole;
  accountStatus?: AccountStatus;
  search?: string;
};

export const useGetOrganizationAccounts = ({
  organizationId,
  pageNumber = DEFAULT_PAGE,
  accountRole,
  accountStatus,
  search,
}: UseGetOrganizationAccounts) =>
  useQuery<OrganizationAccountsResponse, Error>(
    [QUERY_KEY, organizationId, pageNumber, accountRole, accountStatus, search],
    () =>
      getOrganizationAccounts({
        accountRole,
        accountStatus,
        organizationId,
        pageNumber,
        search,
      }),
  );
