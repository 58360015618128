import { InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { PasswordStrengthOptions } from '../../utils/zxcvbn';
import { IconButton } from '../IconButton';
import { DollarSignIcon } from '../Icons/DollarSignIcon';
import { ExpandMoreIcon } from '../Icons/ExpandMoreIcon';
import { VisibilityIcon } from '../Icons/VisibilityIcon';
import { VisibilityOffIcon } from '../Icons/VisibilityOffIcon';
import { PasswordStrengthThermometer } from '../PasswordStrengthThermometer';
import { CurrencyField } from './CurrencyField';
import {
  StyledDatePickerField,
  StyledField,
  StyledIconWrapper,
  StyledMenuItem,
} from './Field.styles';
import { PhoneNumberField } from './PhoneNumberField';
import { FieldProps, FieldTypes } from './types';
import { getMenuItem } from './utils';

export function Field({
  type,
  kind = 'light',
  menuItems,
  iconLeft,
  mb,
  mt,
  ml,
  mr,
  pt,
  pb,
  pl,
  pr,
  margin,
  padding,
  width,
  errorMsg,
  showPasswordThermometer = false,
  passwordStrength = PasswordStrengthOptions.Weakest,
  showRequirementTooltip = false,
  inputProps = {},
  handleDatePickerChange,
  showDescription = false,
  ...rest
}: FieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(
    () => setShowPassword((prev) => !prev),
    [],
  );

  const commonProps = useMemo(
    () => ({
      error: Boolean(errorMsg),
      fullWidth: true,
      helperText: errorMsg,
      kind,
      sx: {
        marginBottom: mb,
        marginTop: mt,
        marginLeft: ml,
        marginRight: mr,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        margin,
        padding,
        width,
      },
      ...rest,
    }),
    [
      errorMsg,
      kind,
      mb,
      mt,
      ml,
      mr,
      pt,
      pb,
      pl,
      pr,
      margin,
      padding,
      width,
      rest,
    ],
  );

  const PasswordField = useMemo(
    () => (
      <>
        <StyledField
          {...commonProps}
          id={`password-${rest.label}`}
          label={rest.label}
          margin="normal"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              ...inputProps,
            },
          }}
          type={showPassword ? 'text' : 'password'}
        />
        {showPasswordThermometer ? (
          <PasswordStrengthThermometer
            showRequirementTooltip={showRequirementTooltip}
            strength={passwordStrength}
          />
        ) : null}
      </>
    ),
    [
      commonProps,
      handleClickShowPassword,
      passwordStrength,
      rest.label,
      showPassword,
      showPasswordThermometer,
      showRequirementTooltip,
      inputProps,
    ],
  );

  const TextField = useMemo(
    () => (
      <StyledField
        {...commonProps}
        slotProps={{
          input: {
            startAdornment: iconLeft && (
              <StyledIconWrapper>{iconLeft}</StyledIconWrapper>
            ),
            ...inputProps,
          },
        }}
      />
    ),
    [commonProps, iconLeft, inputProps],
  );

  const CurrencyComponentField = useMemo(
    () => (
      <CurrencyField
        {...commonProps}
        slotProps={{
          input: {
            startAdornment: (
              <StyledIconWrapper>
                {iconLeft || <DollarSignIcon />}
              </StyledIconWrapper>
            ),
            ...inputProps,
          },
        }}
      />
    ),
    [commonProps, iconLeft, inputProps],
  );

  const SelectField = useMemo(
    () => (
      <StyledField
        {...commonProps}
        select
        slotProps={{
          input: {
            startAdornment: iconLeft && (
              <StyledIconWrapper>{iconLeft}</StyledIconWrapper>
            ),
            ...inputProps,
          },
          select: { IconComponent: ExpandMoreIcon },
        }}
      >
        {menuItems?.map((menuItem) => {
          const { key, value } = menuItem;
          return (
            <StyledMenuItem key={`menuitem-${value}-${key}`} value={key}>
              {getMenuItem(menuItem, showDescription)}
            </StyledMenuItem>
          );
        })}
      </StyledField>
    ),
    [commonProps, iconLeft, inputProps, menuItems, showDescription],
  );

  const PhoneField = useMemo(
    () => <PhoneNumberField {...commonProps} />,
    [commonProps],
  );

  const DateField = useMemo(
    () => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePickerField
          disabled={rest.disabled}
          label={rest.label}
          onChange={handleDatePickerChange}
          value={dayjs(rest.value)}
        />
      </LocalizationProvider>
    ),
    [handleDatePickerChange, rest.disabled, rest.label, rest.value],
  );

  switch (type) {
    case FieldTypes.password:
      return PasswordField;
    case FieldTypes.text:
      return TextField;
    case FieldTypes.currency:
      return CurrencyComponentField;
    case FieldTypes.select:
      return SelectField;
    case FieldTypes.phone:
      return PhoneField;
    case FieldTypes.date:
      return DateField;
    default:
      throw new Error('Invalid prop value for `type`.');
  }
}
