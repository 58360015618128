import {
  Stepper as StepperMui,
  StepperProps as StepperPropsMui,
} from '@mui/material';
import React from 'react';

export type StepperProps = StepperPropsMui;

export function Stepper({ ...rest }: StepperProps) {
  return <StepperMui {...rest} />;
}
