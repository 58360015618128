import React, { PropsWithChildren } from 'react';
import { styled } from '../../utils/styled';
import { Box } from '../Box';

const StyledBox = styled(Box)`
  display: flex;
  position: fixed;
  background-color: ${({ theme }) => theme.palette.background.paper};
  bottom: 0;
  right: 0;
  width: 100%;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
  border-top: 1px solid ${({ theme }) => theme.palette.neutral[200]};
  z-index: 1;
`;

interface FooterFixedProps {
  readonly 'data-testid'?: string;
}

export function FooterFixed({
  children,
  'data-testid': dataTestId,
}: PropsWithChildren<FooterFixedProps>) {
  return <StyledBox data-testid={dataTestId}>{children}</StyledBox>;
}
