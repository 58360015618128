import {
  ListItemAvatar as ListItemAvatarMui,
  ListItemAvatarProps as ListItemAvatarPropsMui,
} from '@mui/material';
import React from 'react';

export type ListItemAvatarProps = ListItemAvatarPropsMui;

export function ListItemAvatar({ ...rest }: ListItemAvatarProps) {
  return <ListItemAvatarMui {...rest} />;
}
