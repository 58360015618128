import {
  ListItemIcon as ListItemIconMui,
  ListItemIconProps as ListItemIconPropsMui,
} from '@mui/material';
import React from 'react';

export type ListItemIconProps = ListItemIconPropsMui;

export function ListItemIcon({ ...rest }: ListItemIconProps) {
  return <ListItemIconMui {...rest} />;
}
