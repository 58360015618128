import { styled } from '../../utils/styled';
import { PageCardContainer } from '../PageCardContainer';

export const LoaderContainer = styled('div')`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: var(--border-radii-outer, 8px);
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 600px;
  width: 511px;
`;

export const StyledPageCardContainer = styled(PageCardContainer, {
  shouldForwardProp: (prop) =>
    !['isSmUp', 'heightCard', 'widthCard'].includes(String(prop)),
})<{
  isSmUp?: boolean;
  heightCard?: string;
  widthCard?: number;
}>`
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.palette.background.paper};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-height: 100vh;
  height: heightCard;
  max-width: 100vw;
  .MuiPaper-root.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiCardContent-root > div {
    min-height: 100px;
    min-width: 90vw;
    @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
      min-width: unset;
      max-height: 844px;
    }
    overflow: auto;
  }
`;
