import { css, Theme } from '@mui/material';
import { SerializedStyles } from '@emotion/react';

export const themeMediaQuery =
  (theme: Theme, key: keyof Theme['breakpoints']['values']) =>
  (
    styles: TemplateStringsArray,
    ...args: (string | number | SerializedStyles)[]
  ) => css`
    ${theme.breakpoints.up(key)} {
      ${css(styles, ...args)}
    }
  `;
