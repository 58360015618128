import React, { useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  ElementWithBadge,
  Flags,
  LogoutIcon,
  PopoverMenu,
  Spacing,
  Stack,
  styled,
  Typography,
  useFlag,
} from 'ui';
import { useAuth } from '@context/AuthContext';
import { PATHS } from '@utils/paths';

const StyledAvatarContainer = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(Spacing.Large)};
  gap: ${({ theme }) => theme.spacing(Spacing.Medium)};
  align-items: center;
  flex-direction: row;
`;

export function MyAccountButton() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [, setSearchParams] = useSearchParams();

  const isSecurityPageVisible = useFlag(Flags.securityPage);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const anchorEl = useRef<HTMLDivElement>(null);

  const menuOptions = useMemo(
    () =>
      [
        isSecurityPageVisible && {
          onClick: () => {
            navigate(PATHS.PRIVATE_BASE.security);
            setSearchParams();
          },
          title: 'Security',
        },
        {
          onClick: () => {
            logout();
            setSearchParams();
          },
          title: 'Sign out',
          icon: (
            <Typography color="error" variant="body">
              <LogoutIcon />
            </Typography>
          ),
        },
      ].filter(Boolean),
    [isSecurityPageVisible, logout, navigate, setSearchParams],
  );

  const userLabel = user?.displayName || user?.email || 'Claim Score';

  return (
    <>
      <Button
        aria-label="User settings"
        color="neutral"
        onClick={() => {
          setIsPopoverOpen((prev) => !prev);
        }}
        startIcon={
          <ElementWithBadge
            badgeColor="error"
            element="avatar"
            name={userLabel}
            ref={anchorEl}
            size="small"
          />
        }
        variant="text"
      />
      <PopoverMenu
        anchorEl={anchorEl.current}
        heading={
          <StyledAvatarContainer>
            <ElementWithBadge element="avatar" name={userLabel} size="large" />
            <Typography variant="h5">{userLabel}</Typography>
          </StyledAvatarContainer>
        }
        isOpen={isPopoverOpen}
        menuItems={menuOptions}
        onClose={() => {
          setIsPopoverOpen(false);
        }}
      />
    </>
  );
}
