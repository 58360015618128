import noop from 'lodash/noop';
import React, { createContext, useContext } from 'react';
import { InviteToCaseModal } from './InviteToCaseModal';
import { InviteToOrgModal } from './InviteToOrgModal';

interface InvitationModalsOrchestratorProps {
  readonly inviteOrgModalOpen: boolean;
  readonly inviteUserModalOpen: boolean;
  readonly setInviteOrgModalOpen: (value: boolean) => void;
  readonly setInviteUserModalOpen: (value: boolean) => void;
}
const InvitationModalsContext =
  createContext<InvitationModalsOrchestratorProps>({
    inviteOrgModalOpen: false,
    inviteUserModalOpen: false,
    setInviteOrgModalOpen: noop,
    setInviteUserModalOpen: noop,
  });

export const useInvitationsModalOrchestrator = () =>
  useContext(InvitationModalsContext);

export function InvitationModalsOrchestrator({
  inviteOrgModalOpen,
  inviteUserModalOpen,
  setInviteOrgModalOpen,
  setInviteUserModalOpen,
}: InvitationModalsOrchestratorProps) {
  return (
    <InvitationModalsContext.Provider
      // There's no need to memoize this value as this component will only rerender whenever the props change.
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        inviteOrgModalOpen,
        inviteUserModalOpen,
        setInviteOrgModalOpen,
        setInviteUserModalOpen,
      }}
    >
      <InviteToOrgModal
        onClose={() => setInviteOrgModalOpen(false)}
        open={inviteOrgModalOpen}
      />
      {inviteUserModalOpen ? (
        <InviteToCaseModal onClose={() => setInviteUserModalOpen(false)} />
      ) : null}
    </InvitationModalsContext.Provider>
  );
}
