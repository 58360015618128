import React, { ChangeEventHandler } from 'react';
import { Field, FieldTypes } from 'ui/src/components/Field';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { InvitationType } from 'services/ClaimscoreApiService/Invitations/types';
import { UserRole } from 'services/ClaimscoreApiService/shared/types';
import { DropdownType } from './types';
import { getUserRoleSelectionItems } from './utils';

interface UserRoleDropdownProps {
  readonly value: UserRole;
  readonly onChange: ChangeEventHandler<HTMLInputElement>;
  readonly dropdownType: DropdownType;
  readonly errorMsg?: string;
  readonly name?: string;
}

export function UserRoleDropdown({
  value,
  onChange,
  dropdownType,
  errorMsg,
  name,
}: UserRoleDropdownProps) {
  const { getUserRoleOrg, getUserRoleCase } = useUserOrgCase();
  const isDropdownTypeOrg = dropdownType === 'organization';
  const userRole = isDropdownTypeOrg ? getUserRoleOrg() : getUserRoleCase();
  const menuItems = userRole
    ? getUserRoleSelectionItems(userRole, dropdownType as InvitationType)
    : [];

  return (
    <Field
      disabled={menuItems.length === 0}
      errorMsg={errorMsg}
      label={isDropdownTypeOrg ? 'Organization Role' : 'Case Role'}
      menuItems={menuItems}
      name={name}
      onChange={onChange}
      type={'select' as FieldTypes}
      value={value || ''}
    />
  );
}
