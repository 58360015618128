import { Modal as ModalMui, ModalProps as ModalPropsMui } from '@mui/material';
import React, { ReactNode } from 'react';
import { IconButton } from '../IconButton';
import { CloseIcon } from '../Icons/CloseIcon';
import { LoaderContainer, StyledPageCardContainer } from './styles';

interface ModalProps
  extends Pick<
    ModalPropsMui,
    'open' | 'sx' | 'onClose' | 'ref' | 'onAnimationEnd'
  > {
  readonly title?: string;
  readonly modalClose?: () => void;
  readonly widthCard?: number;
  readonly loaderModal?: boolean;
  readonly actionButtons?: ReactNode;
  readonly heightCard?: string;
  readonly isSmUp?: boolean;
  readonly children: ReactNode;
}

export function Modal({
  modalClose,
  title = 'Title',
  children,
  widthCard = 600,
  loaderModal,
  actionButtons,
  heightCard,
  isSmUp,
  ...props
}: ModalProps) {
  return (
    <ModalMui {...props}>
      {loaderModal ? (
        <LoaderContainer>{children}</LoaderContainer>
      ) : (
        <StyledPageCardContainer
          Toolbar={
            <IconButton aria-labelledby="modal-close-icon" onClick={modalClose}>
              <CloseIcon
                aria-label="Close Modal"
                id="modal-close-icon"
                title="close"
              />
            </IconButton>
          }
          cardAction={actionButtons}
          heightCard={heightCard}
          isSmUp={isSmUp}
          noPadding
          title={title}
          widthCard={widthCard}
        >
          {children}
        </StyledPageCardContainer>
      )}
    </ModalMui>
  );
}
