import { useQuery } from 'react-query';
import { AccountStatus } from '@shared/types/forms';
import { getCaseAccounts } from 'services/ClaimscoreApiService/Cases/Cases.service';
import type { OrganizationAccountsResponse } from 'services/ClaimscoreApiService/Organizations/types';
import { UserRole } from 'services/ClaimscoreApiService/shared/types';

const QUERY_KEY = 'getCaseAccounts';

export type UseGetCaseAccounts = {
  caseId: string;
  pageNumber?: number;
  pageSize?: number;
  accountRole?: UserRole;
  accountStatus?: AccountStatus;
  search?: string;
};

export const useGetCaseAccounts = ({
  caseId,
  pageNumber = 0,
  accountRole,
  accountStatus,
  search,
}: UseGetCaseAccounts) =>
  useQuery<OrganizationAccountsResponse, Error>(
    [QUERY_KEY, caseId, pageNumber, accountRole, accountStatus, search],
    () =>
      getCaseAccounts({
        caseId,
        pageNumber,
        accountRole,
        accountStatus,
        search,
      }),
    { keepPreviousData: true },
  );
