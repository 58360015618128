import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AddCaseIcon,
  AddDomainIcon,
  Button,
  GroupAddIcon,
  PlusIcon,
  PopoverMenu,
} from 'ui';
import { InvitationModalsOrchestrator } from '@components/InvitationModalsOrchestrator';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { PATHS } from '@utils/paths';

export function AddOptionsButton() {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const navigate = useNavigate();
  const anchorEl = useRef<HTMLButtonElement>(null);

  const { organizationSelected } = useUserOrgCase();

  const [inviteOrgModalOpen, setInviteOrgModalOpen] = useState(false);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);

  const menuOptions = useMemo(
    () => [
      {
        onClick: () => {
          navigate(
            `${PATHS.PRIVATE_BASE.newCaseCreation}?orgId=${organizationSelected.organizationID}`,
            { state: { previousUrl: location.pathname } },
          );
        },
        icon: <AddCaseIcon />,
        iconPosition: 'start' as const,
        title: 'Create New Case',
      },
      {
        divider: true,
      },
      {
        onClick: () => setInviteOrgModalOpen(true),
        iconPosition: 'start' as const,
        icon: <AddDomainIcon />,
        title: 'Add organization user',
      },
      {
        onClick: () => setInviteUserModalOpen(true),
        iconPosition: 'start' as const,
        icon: <GroupAddIcon />,
        title: 'Add case user',
      },
    ],
    [navigate, organizationSelected.organizationID],
  );

  return (
    <>
      <Button
        aria-label="Add/Create"
        color="neutral"
        onClick={() => {
          setIsPopoverOpen((prev) => !prev);
        }}
        ref={anchorEl}
        startIcon={<PlusIcon />}
        variant="text"
      />
      <PopoverMenu
        anchorEl={anchorEl.current}
        isOpen={isPopoverOpen}
        menuItems={menuOptions}
        onClose={() => {
          setIsPopoverOpen(false);
        }}
      />
      <InvitationModalsOrchestrator
        inviteOrgModalOpen={inviteOrgModalOpen}
        inviteUserModalOpen={inviteUserModalOpen}
        setInviteOrgModalOpen={setInviteOrgModalOpen}
        setInviteUserModalOpen={setInviteUserModalOpen}
      />
    </>
  );
}
