import {
  FormControlLabel,
  RadioProps as RadioPropsMui,
  Radio as RadioUi,
} from '@mui/material';
import React, { ReactNode } from 'react';
import {
  ColorNeutral300,
  ColorNeutral600,
  ColorNeutral1000,
} from '../../design-system/colors';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';

const StyledRadioUi = styled(RadioUi)`
  &.Mui-checked:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const getLabelTextColor = (disabled: boolean, checked: boolean) => {
  if (disabled) {
    return ColorNeutral300;
  }
  return checked ? ColorNeutral1000 : ColorNeutral600;
};

export interface RadioProps
  extends Pick<RadioPropsMui, 'name' | 'checked' | 'onClick'> {
  readonly label: string | ReactNode;
  readonly disabled?: boolean;
  readonly value: string | number | undefined;
}
export function Radio({ disabled, value, label, ...props }: RadioProps) {
  return (
    <FormControlLabel
      aria-labelledby={`${label}-radio`}
      control={
        <StyledRadioUi
          {...props}
          id={`${label}-radio`}
          inputProps={{
            'aria-label': label as string,
          }}
        />
      }
      disabled={disabled}
      label={
        <Typography
          color={getLabelTextColor(Boolean(disabled), Boolean(props.checked))}
          variant="body"
        >
          {label}
        </Typography>
      }
      value={value}
    />
  );
}
