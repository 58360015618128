import { useMutation } from 'react-query';
import { createRoleByCase } from 'services/ClaimscoreApiService/Permissions';
import type {
  OrganizationRolePayload,
  OrganizationRoleResponse,
  UpdateCaseRolePayload,
} from 'services/ClaimscoreApiService/Permissions/types';

const MUTATION_KEY = 'createUserCaseRole';

export const useCreateUserCaseRole = () =>
  useMutation<
    Pick<OrganizationRoleResponse, 'roleName' | 'accountID' | 'caseID'>,
    Error,
    UpdateCaseRolePayload
  >(MUTATION_KEY, ({ accountId, caseId, roleName }: OrganizationRolePayload) =>
    createRoleByCase({
      accountId,
      caseId,
      roleName,
    }),
  );
