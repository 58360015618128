import * as Yup from 'yup';
import { maxLength } from '@utils/formInputSizes';
import { FullWidth, HalfWidth } from '@utils/gridSizes';

export const validationSchema = Yup.object({
  firstName: Yup.string().max(maxLength).required('First Name is required'),
  lastName: Yup.string().max(maxLength).required('Last Name is required'),
  email: Yup.string().max(maxLength).email().required('Email is required'),
  roleName: Yup.string().required('Role is required'),
});

export const fieldsMap = [
  { name: 'firstName', label: 'First Name', type: 'text' },
  { name: 'lastName', label: 'Last Name', type: 'text' },
  { name: 'email', label: 'Email', type: 'text' },
  { name: 'companyName', label: 'Company Name', type: 'select' },
];

export const getXsGridField = (field: { name: string }, isSmUp: boolean) => {
  const gridItemSize = isSmUp ? HalfWidth : FullWidth;

  let xs = gridItemSize;

  if (field.name === 'email') xs = FullWidth;
  if (field.name === 'companyName') xs = FullWidth;
  return xs;
};
