import React, { ReactNode, useMemo } from 'react';
import {
  Box,
  ElementWithBadge,
  ListItemAvatar,
  ListItemButton,
  Spacing,
  styled,
  Typography,
} from 'ui';
import { StyledList, StyledListItem } from './RowListWithActions.styles';

interface RowListWithActionsProps {
  readonly rows: {
    readonly rowId: string;
    readonly label: string;
    readonly showAvatar?: boolean;
    readonly secondaryAction?: ReactNode;
    readonly onClick?: () => void;
  }[];
  readonly isReadOnly?: boolean;
  readonly noRoundCorners?: boolean;
}

const StyledBox = styled(Box)`
  gap: ${({ theme }) => theme.spacing(Spacing.Medium)};
  width: 80%;
  @media (max-width: 768px) {
    width: 65%;
  }
`;

export function RowListWithActions({
  rows,
  isReadOnly = false,
  noRoundCorners = false,
}: RowListWithActionsProps) {
  const rowListItems = useMemo(
    () =>
      rows.map(
        ({
          rowId,
          label,
          showAvatar = false,
          secondaryAction = false,
          onClick,
        }) => {
          const listItemContent = (
            <>
              {showAvatar ? (
                <ListItemAvatar>
                  <ElementWithBadge
                    element="avatar"
                    name={label}
                    size="small"
                  />
                </ListItemAvatar>
              ) : null}
              <StyledBox>
                <Typography id={`list-secondary-label-${rowId}`} noWrap>
                  {label}
                </Typography>
              </StyledBox>
            </>
          );

          return (
            <StyledListItem
              isReadOnly={isReadOnly}
              key={`list-secondary-label-${rowId}`}
              noRoundCorners={noRoundCorners}
              onClick={onClick}
              secondaryAction={secondaryAction}
            >
              {isReadOnly ? (
                listItemContent
              ) : (
                <ListItemButton>{listItemContent}</ListItemButton>
              )}
            </StyledListItem>
          );
        },
      ),
    [isReadOnly, noRoundCorners, rows],
  );

  return <StyledList>{rowListItems}</StyledList>;
}
