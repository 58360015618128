import React, { useCallback } from 'react';
import { Button, SendIcon, styled, Typography } from 'ui';
import { ColorGreen600 } from 'ui/src/design-system/colors';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import { useInvitationsModalOrchestrator } from '../../InvitationModalsOrchestrator';
import { useInviteToCase } from '../InviteToCaseContext';

const ContentWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const TextWrapper = styled('div')`
  max-width: 350px;
  text-align: center;
  > h5 {
    margin-bottom: 8px;
  }
`;

export function ConfirmationStepContent() {
  const { casePermissionsSelected } = useUserOrgCase();

  const { setInviteUserModalOpen } = useInvitationsModalOrchestrator();

  const { selectedUsersToInvite, setSelectedUsersToInvite, setActiveStep } =
    useInviteToCase();

  const handleFinish = useCallback(() => {
    setInviteUserModalOpen(false);
    setActiveStep(0);
    setSelectedUsersToInvite([]);
  }, [setActiveStep, setInviteUserModalOpen, setSelectedUsersToInvite]);

  return (
    <ContentWrapper>
      <SendIcon color={ColorGreen600} />
      <TextWrapper>
        <Typography variant="h5">Invitations Sent</Typography>
        <Typography variant="body">
          {`You've successfully added ${selectedUsersToInvite.length} members to the case `}
          <strong>{casePermissionsSelected?.caseName}</strong>
        </Typography>
      </TextWrapper>
      <Button label="Done" onClick={handleFinish} variant="outlined" />
    </ContentWrapper>
  );
}
