import React, { ReactNode } from 'react';
import { Spacing, Stack, styled, themeMediaQuery, Typography } from 'ui';

export interface SettingsFieldBlockProps {
  readonly title: string;
  readonly description: ReactNode;
  readonly content: ReactNode;
  readonly direction?: 'row' | 'column';
}

const Container = styled(Stack, {
  shouldForwardProp: (prop) => !['direction'].includes(String(prop)),
})<{ direction: 'row' | 'column' }>`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(Spacing.Large)};
  ${({ theme, direction }) => themeMediaQuery(theme, 'md')`
    flex-direction: ${direction};
  `}
`;

const TitleBlockContainer = styled(Stack, {
  shouldForwardProp: (prop) => !['direction'].includes(String(prop)),
})<{ direction: 'row' | 'column' }>`
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Medium)};
  margin-right: unset;
  min-width: unset;
  max-width: unset;
  ${({ theme, direction }) => themeMediaQuery(theme, 'md')`
    margin-bottom: unset;
    margin-right: ${theme.spacing(Spacing.Huge)}
    max-width: ${direction === 'row' ? '328px' : 'unset'};
    min-width: ${direction === 'row' ? '328px' : 'unset'};
  `}
`;

export function SettingsFieldBlock({
  title,
  description,
  content,
  direction = 'row',
}: SettingsFieldBlockProps) {
  return (
    <Container direction={direction}>
      <TitleBlockContainer direction={direction}>
        <Typography color="text.primary" variant="h5">
          {title}
        </Typography>
        <Typography color="text.secondary" variant="body">
          {description}
        </Typography>
      </TitleBlockContainer>
      {content}
    </Container>
  );
}
