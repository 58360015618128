import {
  ListItemButton as ListItemButtonMui,
  ListItemButtonProps as ListItemButtonPropsMui,
} from '@mui/material';
import React from 'react';

export type ListItemButtonProps = ListItemButtonPropsMui;

export function ListItemButton({ ...rest }: ListItemButtonProps) {
  return <ListItemButtonMui {...rest} />;
}
