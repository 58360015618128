import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

interface LoadingContext {
  readonly isLoading: boolean;
  readonly setIsLoading: (loading: boolean) => void;
}

const LoadingContext = createContext<LoadingContext>({
  isLoading: false,
  setIsLoading: () => undefined,
});

interface LoadingContextProviderProps extends PropsWithChildren {
  readonly LoadingComponent?: ReactNode;
}

export function LoadingContextProvider({
  children,
  LoadingComponent,
}: LoadingContextProviderProps) {
  const [isLoading, setIsLoading] = useState(false);

  const LoadingComponentRender = useMemo(
    () => LoadingComponent ?? null,
    [LoadingComponent],
  );

  const value = useMemo(() => {
    return {
      isLoading,
      setIsLoading,
    };
  }, [isLoading]);

  return (
    <LoadingContext.Provider value={value}>
      {isLoading ? LoadingComponentRender : null}
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = (): LoadingContext => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingContextProvider');
  }
  return context;
};
