import { Box, Spacing, StepLabel, Stepper, styled, themeMediaQuery } from 'ui';
import { Orientation } from './type';

export const StepperContainer = styled(Box)`
  margin: 0 auto;
  padding: ${({ theme }) =>
    `${theme.spacing(Spacing.Large)} ${theme.spacing(Spacing.None)}`};
  min-width: 342px;
`;
export const StyledStepLabel = styled(StepLabel)`
  .MuiStepLabel-label.Mui-active .MuiTypography-root.MuiTypography-caption {
    font-weight: 700;
  }
`;

export const StyledStepper = styled(Stepper)<{
  orientation: Orientation;
}>`
  .Mui-completed.MuiSvgIcon-root {
    border: none;
    & > path {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  .MuiStepConnector-line {
    min-height: 40px;
  }
  .MuiSvgIcon-root {
    border-radius: ${({ theme }) => theme.borderRadii.circular};
    border: none;
    ${({ theme, orientation }) => themeMediaQuery(theme, 'md')`
      border: ${orientation === 'vertical' ? '1px solid' : 'none'};
      border-color: ${theme.palette.primary.main};
    `}
    border-color: ${({ theme }) => theme.palette.primary.main};
    & > circle {
      color: ${({ theme }) => theme.palette.background.paper};
    }
    & > text {
      fill: ${({ theme }) => theme.palette.primary.main};
      font-size: 16px;
      font-weight: 700;
    }
  }
  .Mui-active.MuiSvgIcon-root {
    border: none;
    color: ${({ theme }) => theme.palette.red[200]};
    & > circle {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    & > text {
      fill: ${({ theme }) => theme.palette.background.paper};
    }
  }
`;
