import { styled } from '@mui/material';
import React, { ReactNode, SVGProps } from 'react';
import { themeMediaQuery } from '../../utils/themeMediaQuery';

const StyledSvg = styled('svg')`
  transform: scale(0.9);
  ${({ theme }) => themeMediaQuery(theme, 'xl')`
    transform: scale(1);
  `}
`;

export interface SvgWrapperProps extends SVGProps<SVGSVGElement> {
  readonly children: ReactNode;
}

export function SvgWrapper({ children, ...props }: SvgWrapperProps) {
  return (
    <StyledSvg xmlns="http://www.w3.org/2000/svg" {...props}>
      {children}
    </StyledSvg>
  );
}
