export function getArrayDifferenceByProperty<T>(
  property: keyof T,
  array1: T[],
  array2: T[],
): T[] {
  const propertySet2 = new Set(array2.map((item) => item[property]));
  const uniqueFromArray1 = array1.filter(
    (item) => !propertySet2.has(item[property]),
  );

  const propertySet1 = new Set(array1.map((item) => item[property]));
  const uniqueFromArray2 = array2.filter(
    (item) => !propertySet1.has(item[property]),
  );
  return [...uniqueFromArray1, ...uniqueFromArray2];
}
